'use strict';

// プラグイン等読み込み

import MicroModal from 'micromodal';  // es6 module

import picturefill from 'picturefill';
import objectFitImages from 'object-fit-images';



import { GetSpriteSvg } from './module/GetSpriteSvg';

import { GetInclude } from './module/GetInclude';
import { Anchor } from './module/Anchor';
import { Accordion } from './module/Accordion';
import { Pagetop } from './module/Pagetop';
import { Menu } from './module/Menu';
import { SetFillHeight } from './module/SetFillHeight';
import { CheckboxConfirm } from './module/CheckboxConfirm';
import { CheckboxOther } from './module/CheckboxOther';
import { Tab } from './module/Tab';
import { TxtCollapse } from './module/TxtCollapse';
import { AnswerSelect } from './module/AnswerSelect';
import { AddressFind } from './module/AddressFind';

GetInclude('/include/header.html', '.js-header');
GetInclude('/include/footer.html', '.js-footer');

GetSpriteSvg();

objectFitImages('.js-objectfit');
MicroModal.init({
	disableScroll: true,
	disableFocus: true,
	awaitOpenAnimation: true,
	awaitCloseAnimation: true,
 });

SetFillHeight();



$(function(){
		
		
		Accordion();
		Pagetop();
		Menu();
		Anchor();
		CheckboxConfirm();
		CheckboxOther();
		Tab();
		TxtCollapse();
		AnswerSelect();
		AddressFind();
		/*setTimeout(function() {
			Pagetop();
			Menu();
		}, 10);*/

		// postal_code.get('1000001', function(address) {
		//   console.log(address.prefecture); // => "東京都"
		//   console.log(address.city); // => "千代田区"
		//   console.log(address.area); // => "千代田"
		//   console.log(address.street); // => ""
		// });
		
});
