'use strict';

export function ResizeEvent(event) {
  let resizeTimer;
  $(window).on('load resize', function() {
    if (resizeTimer !== false) {
      clearTimeout(resizeTimer);
    }
    resizeTimer = setTimeout(function() {
      event();
    }, 10);
  });
}