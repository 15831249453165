'use strict';

import { ResizeEvent } from './ResizeEvent';

export function SetFillHeight() {
  const fillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  
  
  // 初期化

  fillHeight();
  ResizeEvent(fillHeight);
  

}