'use strict';

import postal_code from 'japan-postal-code';

export function AddressFind() {
  $('.js-zipcode').on('click', function() {
    var code = $('#zip_code').val();

    postal_code.get(code, function(address) {
      var result = address.prefecture;
      result = address.city ? result + address.city : result;
      result = address.area ? result + address.area : result;
      result = address.street ? result + address.street : result;
      $('#zip_code_address').val(result);
    });
  })
}