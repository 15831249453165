'use strict';

export function ScrollEvent(event) {
  let scrollTimer;
  $(window).on('load scroll', function() {
    if (scrollTimer !== false) {
      clearTimeout(scrollTimer);
    }
    scrollTimer = setTimeout(function() {
      const scrollHeight = $(document).height();
      const scrollTopHeight = $(window).scrollTop();
      event(scrollHeight, scrollTopHeight);
    }, 10);
  });
}