'use strict';

export function GetSpriteSvg() {
  $.ajax({
    type: 'get',
    url: '/assets/images/global/sprite.svg'
  }).done(function(data) {
    var svg = $(data).find('svg');
    $('body').prepend(svg);
  });
}