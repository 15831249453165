'use strict';

export function Anchor() {
  const headerHeight = $(window).width > 768 ? 72 : 65;
  $('.js-anker[href^="#"]').on('click', function () {
    let href = $(this).attr('href');
    let target = $(href == "#" || href == "" ? 'html' : href);
    let position = target.offset().top - headerHeight - 10;
    $('html, body').animate({
      scrollTop: position
    }, 500);
    return false;
  });
  const urlHash = location.hash;

  if (urlHash) {
    console.log(urlHash)
    $('body,html').stop().scrollTop(0);
    let target = $(urlHash);
    if (target.length) {
      let position = target.offset().top - headerHeight - 10;
      setTimeout(function(){
        $('body,html').stop().animate({scrollTop:position}, 0);
      }, 100);
    }
  }
}
