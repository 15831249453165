'use strict';

export function Menu() {

  let scrollPosition;

  $(document).on('click', '.js-menu-trigger', function(){
    if(!$(this).hasClass('is-open')){
      scrollPosition = $(window).scrollTop();
      $('.js-menu-trigger').addClass('is-open');
      $('.js-menu-target').addClass('is-open');
      $('.l-header').addClass('is-active');
      $('.l-header-logo').addClass('is-active');
      $('body').addClass('is-locked').css({ 'top': -scrollPosition });
    } else {
      $('.js-menu-trigger').removeClass('is-open');
      $('.js-menu-target').removeClass('is-open');
      $('.l-header').removeClass('is-active');
      $('.l-header-logo').removeClass('is-active');
      $('body').removeClass('is-locked').css({ 'top': '' });
        window.scrollTo(0, scrollPosition);
    }
  });
}