'use strict';

export function TxtCollapse() {
  $(document).on('click', '.js-txt-collapse-trigger', function(){
    $(this).toggleClass('is-active');
    $(this).parent().find('.p-collapse-txt__content').toggleClass('is-active');

    if (!$(this).hasClass('is-active')) {
      var t = $(this).closest('.c-box');
      $('body,html').animate({
        scrollTop: $(t).offset().top - $('header').height() - 30
      }, 100);
    }
    return false;
  });
}