'use strict';

export function CheckboxConfirm() {
  $('.js-confirm').on('change', function() {
    if ($(this).is(":checked")) {
      $(this).parent().next().find('.c-btn-b:not(.c-btn--return) .c-btn-b__link').removeClass('c-btn__link--disabled');
    }
    else {
      $(this).parent().next().find('.c-btn-b:not(.c-btn--return) .c-btn-b__link').addClass('c-btn__link--disabled');
    }
    return false;
  });

  var c = $('.js-link-confirm').length, t = 0;
  $('.js-link-confirm').on('click', function() {
    if (!$(this).data('click'))
      t++;
    $(this).attr('data-click', 'true');
    if (t == c)
      $('.js-confirm')[0].removeAttribute('disabled');
  })
}