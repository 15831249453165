'use strict';

export function Tab() {
  $('.js-tab-trigger').on('click', function() {
    var t = $(this).data('tab');
    $('.js-tab-trigger').removeClass('is_active');
    $(this).addClass('is_active');
    $('.js-tab-content').removeClass('is-active');
    $('#' + t).addClass('is-active');
    return false;
  });
}