'use strict';

export function CheckboxOther() {
  $('.js-checkbox-other').on('change', function() {
    if ($(this).is(":checked")) {
      $(this).parent().parent().parent().find('.p-question__answer--textarea').fadeIn();
    }
    else {
      $(this).parent().parent().parent().find('.p-question__answer--textarea').fadeOut();
    }
    
    return false;
  });
}