'use strict';

export function GetInclude(item, target) {
  fetch(item).then(function(response) {
    return response.text();
  }).then(function(data) {
    document.querySelector(target).innerHTML = data;
    return true;
  }).finally(function() {
    return true;
  })
  //return true;
}