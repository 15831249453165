'use strict';

export function AnswerSelect() {
  $(document).on('click', '.p-question__answer', function(){
    $(this).parent().find('.is-active').removeClass('is-active');
    $(this).toggleClass('is-active');

    $(this).find('input[type="radio"]').prop('checked', true);
    
    $(this).parent().find('.p-question__answer--warning').hide();
    $(".p-question-option").removeClass("show");
    if ($(".choose-yes").hasClass("is-active")) {
      $(".p-question-option").addClass("show");
    }
    return false;
  });

  $(document).on('focus', '.p-question__answer--textarea', function(){
    $(this).parent().find('.p-question__answer--warning').hide();
    return false;
  });

  $(document).on('change', '.p-question__answer--date .c-selectBG select', function(){
    $(this).closest('.p-question__answers').find('.p-question__answer--warning').hide();
    return false;
  });
}
