'use strict';

import { ScrollEvent } from './ScrollEvent';

export function Pagetop() {
  $(document).on('click', '.js-pagetop', function(){
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });

  const PageTopFix = function(scrollHeight, scrollTopHeight) {
    const triggerHeight = $('.js-pagetop').height()　/ 2;
    let bottomHeight = 0;
    if($('.l-bottom-fix').length) {
      bottomHeight = $('.l-bottom-fix').height();
    } else {
      $('.js-pagetop').addClass('is-not-bottom');
    }
    const scrollPosition = window.innerHeight + scrollTopHeight - triggerHeight - bottomHeight;
    const footHeight = $('.l-footer').height();
    if (scrollHeight - scrollPosition  <= footHeight) {
      $('.js-pagetop').removeClass('is-fixed');
    } else {
      $('.js-pagetop').addClass('is-fixed');
    }
    if ($(window).scrollTop() > 100) {
      $('.l-footer-pagetop').fadeIn();
    } else {
      $('.l-footer-pagetop').fadeOut();
    }
  }

  ScrollEvent(PageTopFix);

}